import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import gsap from 'gsap'
import parse from 'html-react-parser'
import React, { FC, useEffect, useRef, useState } from 'react'

import useOnScreen from '../../hooks/useOnScreen'
import {
  Aside,
  BlockWrapper,
  ContentContainer,
  ContentWrapper,
  ImageContainer,
  SectionBGBlock,
  SectionBlock,
  Wrapper,
} from './styles'

interface IContentProps {
  id: string
  category: string
  title: string
  rich_description: string
  image: {
    imgixImage: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  background_image: {
    imgixImage: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

interface IStickyScollingSectionProps {
  content: IContentProps[]
}

interface IStickySectionModuleProps extends IContentProps {
  onVisible: (id: string) => void
}

const StickySectionModule: FC<IStickySectionModuleProps> = ({
  id,
  category,
  title,
  rich_description,
  image,
  onVisible,
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null)

  const isOnScreen = useOnScreen(elementRef, `-50% 0% -50% 0%`)

  useEffect(() => {
    if (isOnScreen) {
      onVisible(id)
    }
  }, [isOnScreen])

  return (
    <SectionBlock className="jumpLink" data-id={id} key={id} ref={elementRef}>
      <GatsbyImage
        alt={title}
        backgroundColor="transparent"
        className="hidden-dt"
        image={image.imgixImage.gatsbyImageData}
      />
      <div className="Block-content HeaderBlock-content">
        <Typography component="p" variant="subtitle1">
          {category.toUpperCase()}
        </Typography>
        <Typography className="categorySubhead" component="h3" variant="h2">
          {title}
        </Typography>
        <Typography component="p" variant="subtitle1">
          {rich_description ? parse(rich_description) : null}
        </Typography>
      </div>
    </SectionBlock>
  )
}

const StickyScrollingSection = ({ content }: IStickyScollingSectionProps) => {
  const scrollingImages = useRef<HTMLDivElement | null>(null)

  const [currentModule, setCurrentModule] = useState<string>()

  const handleChangeModule = (id: string) => {
    if (id === currentModule) {
      return
    }
    gsap.to(scrollingImages.current, {
      alpha: 0,
      duration: 0.2,
      onComplete: () => {
        setCurrentModule(id)
      },
    })
  }

  useEffect(() => {
    gsap.to(scrollingImages.current, {
      alpha: 1,
      duration: 0.3,
    })
  }, [currentModule])

  const currentModuleContent =
    (currentModule &&
      currentModule !== '' &&
      content?.find((el) => el.id === currentModule)) ||
    undefined

  const currentModuleImage =
    currentModuleContent?.image?.imgixImage?.gatsbyImageData &&
    getImage(currentModuleContent.image.imgixImage.gatsbyImageData)

  return (
    <Wrapper>
      <ContentWrapper>
        <BlockWrapper>
          <ImageContainer className="hidden-md">
            {content.map((topic, i) => {
              const sectionImage = getImage(
                topic?.background_image?.imgixImage?.gatsbyImageData,
              )

              return (
                <SectionBGBlock
                  blockId={i}
                  className={currentModule === topic.id ? 'onscreen' : ''}
                  key={`bg-image-${topic.id}`}
                >
                  {sectionImage ? (
                    <GatsbyImage alt="background image" image={sectionImage} />
                  ) : null}
                </SectionBGBlock>
              )
            })}

            <Aside ref={scrollingImages}>
              {/* {content.map(({ id, image, title }) => (
                <GatsbyImage
                  id={id}
                  key={`swap-image-${id}`}
                  image={image?.imgixImage?.gatsbyImageData}
                  alt={title}
                />
              ))} */}

              {currentModuleImage ? (
                <GatsbyImage
                  alt={currentModuleContent.title}
                  image={currentModuleImage}
                />
              ) : null}
            </Aside>
          </ImageContainer>
          <ContentContainer>
            {content.map((topic) => (
              <StickySectionModule
                key={topic.id}
                onVisible={(id) => handleChangeModule(id)}
                {...topic}
              />
            ))}
          </ContentContainer>
        </BlockWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default StickyScrollingSection
