import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const Wrapper = styled.section`
  display: block;
  position: relative;

  background: ${rspTheme.palette.secondary.background};
  .hidden-dt {
    @media (min-width: ${rspTheme.breakpoints.values.md}px) {
      display: none !important;
    }
  }
  .hidden-md {
    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      display: none !important;
    }
  }
`

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 20;
`

export const BlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 512fr 608fr;
  grid-template-rows: max-content 1fr;
  column-gap: 80px;
  max-width: 1440px;
  padding: 0 80px;
  margin: 0px auto;
  @media (max-width: ${rspTheme.breakpoints.values.md}px) {
    display: flex;
    flex-direction: column;
    max-width: 90%;
  }
`

export const ImageContainer = styled.div`
  grid-row: 1 / span 4;
  grid-column: 2;
  grid-row: 1;
  position: relative;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;

  .categorySubhead {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 24px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    width: 100%;

    .categorySubhead {
      font-size: 2.8rem;
      margin-top: 24px;
    }
  }
`

export const SectionBGBlock = styled.div<{ blockId: number }>`
  height: 80vh;
  position: absolute;
  top: ${({ blockId }) => (blockId !== undefined ? 80 * blockId : 80)}vh;
  opacity: 0;
  transition: opacity 1s ease-out;
  &.onscreen {
    opacity: 1;
  }
`

export const SectionBlock = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${rspTheme.breakpoints.values.md}px) {
    height: auto;
    margin: 24px auto;
    .gatsby-image-wrapper {
      margin-bottom: 24px;
    }
  }
`

export const Aside = styled.aside`
  position: sticky;
  top: calc(${rspTheme.navHeight});
  right: 0;
  height: calc(100vh - ${rspTheme.navHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* .gatsby-image-wrapper {
    opacity: 0;
    
    display: none;
    height: 0;
    &.active {
      opacity: 1;
      height: unset;
      display: block;
    }
  } */
`
