import Box from '@material-ui/core/Box'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

interface RowProps {
  row: number
}

export const AlternatingContentGridSection = styled.section`
  background-color: ${rspTheme.palette.primary.background};
  padding: 96px 0 96px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 60px 0 60px;
  }
`

export const RowBackground = styled.div<RowProps>`
  position: relative;
  overflow-x: visible;

  .gatsby-image-wrapper {
    ${({ row }) => (row === 0 ? 'margin-left: -5%;' : 'margin-right: -5%;')}
  }
`

export const RowGrid = styled.div<RowProps>`
  display: grid;
  grid-template-columns: ${({ row }) => (row === 0 ? '3fr 2fr' : '2fr 3fr')};
  grid-column-gap: 80px;
  margin: 80px 0;
  text-align: left;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    grid-template-columns: 1fr;
    margin: 24px 0 0;
  }
`

export const Column = styled.div`
  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    grid-row-start: 2;
    margin-top: 24px;
  }

  > * + h2 {
    margin-top: 88px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin-top: 48px;
    }
  }
`

export const Wrapper = styled(Box)({
  width: `90%`,
  maxWidth: `1140px`,
  margin: `0 auto 0`,
})

export const PosterImage = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-out;
  pointer-events: auto;
  cursor: pointer;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
  }
`

export const VideoFrame = styled.iframe`
  width: 100%;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  opacity: 0;
  transition: all 0.5s ease-out;
  z-index: 104;

  &.active {
    opacity: 1;
  }
`

export const VideoFrameBackground = styled.div`
  position: absolute;
  display: block;
  background: ${rspTheme.palette.primary.main};
  z-index: 103;
  &.active {
    width: 70% !important;
    height: 70% !important;
    left: 15% !important;
    top: 15% !important;
    background: transparent;
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const VideoWrapper = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80%;
  transition: all 0.5s ease-out;
  z-index: 10;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    width: 100%;
    height: 100%;
    min-height: 270px;
  }
`

export const CloseButton = styled.button`
  padding: 0;
  border: 2px solid #ffffff;
  background-color: ${rspTheme.palette.primary.main};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  position: absolute;
  top: 9%;
  right: 9%;
  transform-origin: center;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  z-index: 105;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`

export const VideoModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10%;
  z-index: 102;
  background: ${rspTheme.palette.primary.background}aa;
  opacity: 0;
  transition: all 0.3s ease-out;
  &.active,
  .active {
    opacity: 1;
  }
`

export const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
