import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import AlternatingContentGrid from '../components/AlternatingContentGrid'
import ContactUsForm from '../components/ContactUsForm'
import FeatureOverview from '../components/FeatureOverview'
import Hero from '../components/Hero'
import ResourceCards from '../components/Homepage/ResourceCards'
import Layout from '../components/Layout'
import ModalTeaser from '../components/ModalTeaser'
import NewsletterSignup from '../components/NewsletterSignup'
import PageBody from '../components/PageBody'
import ProductPromoCard from '../components/ProductPromoCard'
import RelatedResources from '../components/RelatedResources'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import StickyScrollingSection from '../components/StickyScrollingSection'
import TrainingProgramStages from '../components/TrainingProgramStages'
import createDownloadObject from '../utils/createDownloadObject'

const TechnologyOverviewPage = (
  props: PageProps<Queries.technologyOverviewPageQuery>,
) => {
  const pageData = props?.data?.contentstackPageTechnologyOverview
  const parentPage = props?.data?.parentCategoryPage?.nodes?.[0]

  const breadcrumbLinks = [{ text: pageData?.title || '', target: '' }]
  if (parentPage?.title && parentPage?.url) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.url,
    })
  }

  const allProductPages = props?.data?.productPromoPages?.nodes

  let resourcesByFilter
  switch (props.pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...props?.data?.resourceCardsByResourceTypeFilter?.nodes,
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [...props?.data?.resourceCardsByIndustryFilter?.nodes]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...props?.data?.resourceCardsByRoleFilter?.nodes]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards =
    props?.data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = createDownloadObject(props, 'props')

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />

      {pageData?.introduction?.[0] ? (
        <FeatureOverview
          body={
            pageData?.introduction?.[0]?.overview_description ||
            pageData.introduction?.[0]?.topic?.[0]?.overview_description
          }
          className="singleColumn"
          headline={
            pageData?.introduction?.[0]?.headline ||
            pageData.introduction?.[0]?.topic?.[0]?.overview_headline
          }
          isTech
        />
      ) : null}
      {pageData?.optional_sections?.map((section: any, index) => (
        <React.Fragment key={`${section.id}${index}`}>
          {section?.internal?.type === 'Contentstack_section_lead_gen_form' && (
            <ContactUsForm className="technology" formContent={section} />
          )}
          {section?.internal?.type ===
            'Contentstack_section_product_category_links' && (
            <TrainingProgramStages
              body={section.short_description}
              categories={section}
              headline={section.headline}
              isProgramStage={false}
              locale={pageData?.locale}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_feature_overview' && (
            <FeatureOverview
              body={
                section.overview_description ||
                section.topic?.[0]?.overview_description
              }
              headline={
                section.headline || section.topic?.[0]?.overview_headline
              }
              theme="White"
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_product_promo_card' && (
            <ProductPromoCard
              ctaText={section.cta_text}
              ctaUrl={
                allProductPages?.filter(
                  (page: any) =>
                    page.product?.[0]?.id === section.promoted_product?.[0]?.id,
                )?.[0]?.url
              }
              imagePosition={section.image_position}
              promotedProduct={section.promoted_product}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_stick_image_scrolling' && (
            <StickyScrollingSection content={section?.content} />
          )}
          {section?.internal?.type ===
            'Contentstack_component_subscribe_form' && (
            <NewsletterSignup className="technology" content={section} />
          )}
          {section?.internal?.type === 'Contentstack_section_page_body' && (
            <PageBody
              content={section}
              downloadResource={downloadResourceObject}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_alternating_content' && (
            <AlternatingContentGrid content={section.content} />
          )}
          {section?.internal?.type ===
            'Contentstack_section_resource_card_set' && (
            <RelatedResources
              content={{
                related_resources: resourcesByFilter,
                ...section,
              }}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_resource_card_carousel' && (
            <ResourceCards
              content={section}
              resources={allFeaturedResourceCards}
            />
          )}
          {section?.internal?.type === 'Contentstack_section_modal_teaser' && (
            <ModalTeaser content={section} />
          )}
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.technologyOverviewPageQuery>) => (
  <>
    <SchemaMarkup
      data={data.contentstackPageTechnologyOverview?.schema_markup}
    />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageTechnologyOverview?.title}
      parent={data?.parentCategoryPage?.nodes?.[0]}
    />
  </>
)

export default TechnologyOverviewPage

export const data = graphql`
  query technologyOverviewPage(
    $pageId: String
    $filterId: String
    $locale: String
    $parentCategoryId: String
    $downloadResourceId: String
  ) {
    contentstackPageTechnologyOverview(id: { eq: $pageId }) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      introduction {
        ...FeatureOverviewSection
      }
      optional_sections {
        ... on Contentstack_section_page_body {
          ...PageBodySection
        }
        ... on Contentstack_section_alternating_content {
          ...AlternatingContentSection
        }
        ... on Contentstack_section_lead_gen_form {
          ...LeadGenForm
        }
        ... on Contentstack_section_modal_teaser {
          ...ModalTeaserSection
        }
        ... on Contentstack_component_subscribe_form {
          ...SubscribeFormComponent
        }
        ... on Contentstack_section_feature_overview {
          ...FeatureOverviewSection
        }
        ... on Contentstack_section_product_promo_card {
          ...ProductPromoCard
        }
        ... on Contentstack_section_resource_card_set {
          ...RelatedResourcesSection
        }
        ... on Contentstack_section_resource_card_carousel {
          ...ResourceCardCarouselSection
        }
        ... on Contentstack_section_stick_image_scrolling {
          ...StickyImageScrolling
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: { publish_details: { locale: { eq: $locale } } }
      limit: 15
    ) {
      nodes {
        id
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    productPromoPages: allContentstackPageProduct(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        id
        url
        product {
          id
        }
      }
    }

    parentCategoryPage: allContentstackPageTechnologyOverview(
      filter: {
        publish_details: { locale: { eq: $locale } }
        product_category: { elemMatch: { id: { eq: $parentCategoryId } } }
      }
    ) {
      nodes {
        title
        url
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            is_gated: { eq: "Yes" }
            id: { eq: $downloadResourceId }
          }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 72 })
            }
          }
        }
      }
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
      }
    }
  }
`
