import CloseIcon from '@material-ui/icons/Close'
import Vimeo from '@u-wave/react-vimeo'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import gsap from 'gsap'
import React, { useEffect, useState } from 'react'

import playButton from '../../images/playbutton.png'
import HeadBodyCTA from '../UI/HeadBodyCTA'
import {
  AlternatingContentGridSection,
  CloseButton,
  Column,
  PlayButton,
  PosterImage,
  RowBackground,
  RowGrid,
  VideoFrameBackground,
  VideoModal,
  VideoWrapper,
  Wrapper,
} from './styles'

interface AlternatingContentGridProps {
  content: {
    id: string
    title: string
    video_url: string
    video_poster_image: {
      imgixImage: {
        gatsbyImageData: IGatsbyImageData
      }
    }
    description: string
    cta_link: [
      {
        id: string
        external_url: string
        links_to_: string
        open_in_new_window_: string
        text: string
        title: string
      },
    ]
  }[]
}

const AlternatingContentGrid = ({ content }: AlternatingContentGridProps) => {
  const [activeVideo, setActiveVideo] = useState({ video: '', rect: {} })

  const handlePosterClick = (e, video: string) => {
    // e.target.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center',
    //   inline: 'center',
    // });

    if (!document.body.classList.contains('scroll-lock')) {
      document.body.classList.add('scroll-lock')
    }

    setActiveVideo({ video, rect: e.target.getBoundingClientRect() })
  }

  useEffect(() => {
    if (activeVideo.video !== '') {
      const videoWidth = window.innerWidth * 0.8
      const videoHeight = (videoWidth * 9) / 16

      const bg = document.getElementById('videoBackgroundRef')

      gsap.fromTo(
        bg,
        {
          opacity: 0,
          width: activeVideo.rect.width,
          height: activeVideo.rect.height,
          top: activeVideo.rect.top,
          left: activeVideo.rect.left,
        },
        {
          opacity: 1,
          width: videoWidth,
          height: videoHeight,
          top: window.innerHeight * 0.1,
          left: window.innerWidth * 0.1,
        },
      )
    }
  }, [activeVideo])

  const handleCloseModal = () => {
    if (document.body.classList.contains('scroll-lock')) {
      document.body.classList.remove('scroll-lock')
    }

    const videoWidth = window.innerWidth * 0.8

    const bg = document.getElementById('videoBackgroundRef')

    gsap.to(bg, {
      opacity: 0,
      width: activeVideo.rect.width,
      height: activeVideo.rect.height,
      top: activeVideo.rect.top,
      left: activeVideo.rect.left,
      onComplete: () => {
        bg?.classList.remove('active')
        setActiveVideo({ video: '', rect: {} })
      },
    })
  }

  const handleVideoLoaded = () => {
    const bg = document.getElementById('videoBackgroundRef')
    bg?.classList.add('active')
  }

  return (
    <AlternatingContentGridSection>
      {content?.map((item: any, i: number) => (
        // <div key={item.id} className={i % 2 === 0 ? 'imageRight' : 'imageLeft'}>
        <RowBackground key={item.id} row={i}>
          <Wrapper>
            <RowGrid row={i}>
              {item?.video_url && i % 2 === 0 ? (
                <VideoWrapper>
                  <PosterImage
                    onClick={(e) => {
                      const videoId = item.video_url
                        .split('/')
                        [item.video_url.split('/').length - 1].split('?')[0]

                      handlePosterClick(e, videoId)
                    }}
                  >
                    {item?.video_poster_image?.imgixImage ? (
                      <GatsbyImage
                        alt={item.title}
                        image={getImage(item?.video_poster_image?.imgixImage)}
                      />
                    ) : null}

                    <PlayButton alt="play video" src={playButton} />
                  </PosterImage>
                </VideoWrapper>
              ) : null}
              <Column>
                <HeadBodyCTA
                  body={item.description}
                  cta={{
                    text: item?.cta_link?.[0]?.text,
                    link: [{ url: item?.cta_link?.[0]?.external_url }],
                  }}
                  headline={item.title}
                  headlineColor="primaryDark"
                  key={item.id}
                  resizeHeadline={true}
                />
              </Column>
              {item.video_url && i % 2 === 1 ? (
                <VideoWrapper>
                  <PosterImage
                    onClick={(e) => {
                      const videoId = item.video_url
                        .split('/')
                        [item.video_url.split('/').length - 1].split('?')[0]

                      handlePosterClick(e, videoId)
                    }}
                  >
                    {item?.video_poster_image?.imgixImage ? (
                      <GatsbyImage
                        alt={item.title}
                        image={getImage(item?.video_poster_image?.imgixImage)}
                      />
                    ) : null}
                    <PlayButton alt="play video" src={playButton} />
                  </PosterImage>
                </VideoWrapper>
              ) : null}
            </RowGrid>
          </Wrapper>
        </RowBackground>
        // </div>
      ))}

      {activeVideo.video !== '' && (
        <VideoModal className={activeVideo.video !== '' ? 'active' : ''}>
          <CloseButton onClick={handleCloseModal}>
            <CloseIcon />
          </CloseButton>

          <VideoFrameBackground className="" id="videoBackgroundRef">
            <div className="embed-container">
              {/* <iframe
                className={activeVideo.video !== '' ? 'active' : ''}
                src={`${activeVideo.video}&autoplay=1&autopause=0`}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe> */}

              <Vimeo
                autoplay
                onPlay={handleVideoLoaded}
                video={activeVideo.video}
              />
            </div>
          </VideoFrameBackground>
        </VideoModal>
      )}
    </AlternatingContentGridSection>
  )
}

export default AlternatingContentGrid
