import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ElementType } from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ModalTeaserSection = styled.section`
  background-color: ${rspTheme.palette.primary.background};
  padding: 96px 0 96px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 60px 0 60px;
  }
`

export const Title = styled(Typography)<{ component: ElementType }>`
  margin-bottom: 24px;
  color: ${rspTheme.palette.primary.dark};
`

export const Description = styled(Typography)<{ component: ElementType }>`
  margin-bottom: 24px;
  text-align: center;
`

export const Wrapper = styled(Box)({
  width: `90%`,
  maxWidth: `1140px`,
  margin: `0 auto 0`,
  display: `flex`,
  alignItems: `center`,
  flexDirection: 'column',
})

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-out;
  pointer-events: auto;
  cursor: pointer;
  transform: scale(1);
  display: flex;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }

  & .gatsby-image-wrapper {
    width: 100%;
  }
`

export const ModalBackground = styled.div`
  position: absolute;
  display: block;
  background: white;
  z-index: 103;
  &.active {
    width: 70% !important;
    height: 70% !important;
    left: 15% !important;
    top: 15% !important;
    background: transparent;
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const ImageWrapper = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80%;
  transition: all 0.5s ease-out;
  z-index: 10;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  @media screen and (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    width: 100%;
    height: 100%;
  }
`

export const CloseButton = styled.button`
  padding: 0;
  border: 2px solid #ffffff;
  background-color: ${rspTheme.palette.primary.main};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  position: absolute;
  top: 9%;
  right: 9%;
  transform-origin: center;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  z-index: 105;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10%;
  z-index: 102;
  background: ${rspTheme.palette.primary.background}aa;
  opacity: 0;
  transition: all 0.3s ease-out;
  &.active,
  .active {
    opacity: 1;
  }
`

export const ClickHereButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const ModalContent = styled.div`
  max-width: 857px;
  grid-column: 1 / 10;
  display: inline-grid;

  h2,
  h3 {
    color: ${rspTheme.palette.primary.main};
    font-size: ${rspTheme.typography.h3.fontSize};
    font-weight: ${rspTheme.typography.h3.fontWeight};
    line-height: ${rspTheme.typography.h3.lineHeight};
    letter-spacing: ${rspTheme.typography.h3.letterSpacing};
    margin: 40px 0 16px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin: 24px 0 36px;
    }
  }

  p {
    display: inline-grid;
    gap: 24px;
    grid-template-columns: auto auto;
    align-items: center;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      grid-template-columns: 1fr;
    }
  }

  p,
  ul,
  ol {
    color: ${rspTheme.palette.text.primary};
    font-size: ${rspTheme.typography.body1.fontSize};
    font-weight: ${rspTheme.typography.body1.fontWeight};
    line-height: ${rspTheme.typography.body1.lineHeight};
    letter-spacing: ${rspTheme.typography.body1.letterSpacing};
    margin-bottom: 16px;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      line-height: 1.9;
    }
  }

  blockquote {
    font-style: italic;
    margin: 0 1.6rem 1.6rem;
  }

  ul {
    list-style: none;

    li {
      position: relative;
      margin-bottom: 8px;

      &::before {
        content: '•';
        position: absolute;
        color: ${rspTheme.palette.primary.light};
        font-size: 2rem;
        font-weight: 400;
        left: -16px;
        line-height: 1.5;
      }
    }
  }

  a {
    color: inherit;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
    text-decoration: none;

    &:focus,
    &:hover {
      color: ${rspTheme.palette.primary.main};
    }
  }

  strong {
    font-weight: 400;
    font-size: inherit;
  }

  img {
    display: block;
    order: Left;
    margin: 0;
    max-width: 100%;
    height: auto;
    border-radius: 8px;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      order: 0;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  strong {
    font-weight: 400;
    font-size: inherit;
  }

  ul,
  ol {
    margin-block-start: 0;
    padding-inline-start: 16px;
  }

  iframe {
    --frame-width: 640px;
    /* border-radius: 8px; */
    margin: 16px 0;
    /* For Vimeo embeds; the width and height are set by inline styles, so we need
    to use !important to override that at points where the iFrame is too wide;
    dividing by 1.78 preserves the aspect ratio */
    /* width: var(--frame-width) !important;
    height: calc(var(--frame-width) / 1.78) !important; */

    @media (max-width: 1100px) {
      --frame-width: 600px;
    }
    @media (max-width: 1024px) {
      --frame-width: 500px;
    }
    @media (max-width: 840px) {
      --frame-width: 400px;
    }
    @media (max-width: 680px) {
      --frame-width: 340px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    grid-column: 1 / -1;
  }
`
