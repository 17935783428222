import CloseIcon from '@material-ui/icons/Close'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import gsap from 'gsap'
import parse, { HTMLReactParserOptions } from 'html-react-parser'
import React, { useEffect, useState } from 'react'

import clickHere from '../../images/click-here.png'
import Lightbox from '../Lightbox'
import LinkCTA from '../UI/LinkCTA'
import {
  ClickHereButton,
  CloseButton,
  Description,
  ImageContainer,
  ImageWrapper,
  Modal,
  ModalBackground,
  ModalContent,
  ModalTeaserSection,
  Title,
  Wrapper,
} from './styles'

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    const [lightboxImage, setLightboxImage] = useState<string | null>(null)

    if ('attribs' in domNode && domNode.attribs) {
      if (domNode.name === 'img') {
        const src = domNode.attribs.src

        return (
          <div>
            <Lightbox
              lightboxImage={lightboxImage}
              setLightboxImage={setLightboxImage}
            />
            <img
              alt={domNode.attribs.alt}
              loading="lazy"
              onClick={() => setLightboxImage(`${src}?auto=webp&width=1920`)}
              src={`${src}?auto=webp&width=1920`}
            />
          </div>
        )
      }
    }

    return undefined
  },
}

const parseContent = (content: string) => {
  // change the h3 to h2 with Regex in the body.
  const reg = new RegExp(/(<\/?h)(3)/, 'ig')
  const updatedBody = content.replace(reg, '$12')

  return parse(updatedBody, options)
}

interface ModalTeaserProps {
  content: {
    id: string
    title: string
    description_top?: string
    description_bottom?: string
    thumbnail: {
      imgixImage: {
        gatsbyImageData: IGatsbyImageData
      }
    }
    modal_content: string
    cta_link: [
      {
        id: string
        external_url: string
        links_to_: string
        open_in_new_window_: string
        text: string
        title: string
      },
    ]
  }
}

const ModalTeaser = ({ content }: ModalTeaserProps) => {
  const [modalContent, setModalContent] = useState<{
    open: boolean
    rect: DOMRect | null
  }>({ open: false, rect: null })

  const handleThumbnailClick = (event) => {
    if (!document.body.classList.contains('scroll-lock')) {
      document.body.classList.add('scroll-lock')
    }

    setModalContent({ open: true, rect: event.target.getBoundingClientRect() })
  }

  useEffect(() => {
    if (modalContent.open) {
      const modalWidth = window.innerWidth * 0.8
      const modalHeight = window.innerHeight * 0.8

      const bg = document.getElementById('modalBackgroundRef')
      bg?.classList.add('active')

      gsap.fromTo(
        bg,
        {
          opacity: 0,
          width: modalContent?.rect?.width,
          height: modalContent?.rect?.height,
          top: modalContent?.rect?.top,
          left: modalContent?.rect?.left,
        },
        {
          opacity: 1,
          width: modalWidth,
          height: modalHeight,
          top: window.innerHeight * 0.1,
          left: window.innerWidth * 0.1,
        },
      )
    }
  }, [modalContent])

  const handleCloseModal = () => {
    if (document.body.classList.contains('scroll-lock')) {
      document.body.classList.remove('scroll-lock')
    }

    const bg = document.getElementById('modalBackgroundRef')

    gsap.to(bg, {
      opacity: 0,
      width: modalContent?.rect?.width,
      height: modalContent?.rect?.height,
      top: modalContent?.rect?.top,
      left: modalContent?.rect?.left,
      onComplete: () => {
        bg?.classList.remove('active')
        setModalContent({ open: false, rect: null })
      },
    })
  }

  const parsedContent = parseContent(content.modal_content)

  const image =
    content?.thumbnail?.imgixImage &&
    getImage(content.thumbnail.imgixImage.gatsbyImageData)

  return (
    <ModalTeaserSection>
      <Wrapper>
        <Title component="h2" variant="h2">
          {content.title}
        </Title>
        {content.description_top ? (
          <Description component="p" variant="body1">
            {content.description_top}
          </Description>
        ) : null}
        <ImageWrapper>
          <ImageContainer
            onClick={(event) => {
              handleThumbnailClick(event)
            }}
          >
            {image ? (
              <GatsbyImage
                alt={content.title}
                image={image}
                objectFit="cover"
              />
            ) : null}

            <ClickHereButton alt="open" src={clickHere} />
          </ImageContainer>
        </ImageWrapper>
        {content.description_bottom ? (
          <Description component="p" variant="body1">
            {content.description_bottom}
          </Description>
        ) : null}
        <LinkCTA
          arrow={true}
          directUrl={content.cta_link[0].external_url}
          link={content.cta_link[0]}
          size="buttonMd"
          variant="primary"
        >
          {content.cta_link[0].text}
          <span className="sr-only">: {content.title}</span>
        </LinkCTA>
      </Wrapper>

      {modalContent.open === true && (
        <Modal className="active">
          <CloseButton onClick={handleCloseModal}>
            <CloseIcon />
          </CloseButton>

          <ModalBackground className="" id="modalBackgroundRef">
            <div className="embed-container">
              <ModalContent>{parsedContent}</ModalContent>
            </div>
          </ModalBackground>
        </Modal>
      )}
    </ModalTeaserSection>
  )
}

export default ModalTeaser
